





























































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Song, SongEntry} from "@/model/song";
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import {YOUTUBE_CHANNEL_URL} from "@/constants/boodka-fm.constant";
import router from "@/router";
import { Base64 } from 'js-base64';

@Component({})
export default class Slider extends Vue {
  selectedSongEntry: SongEntry = {};
  entries: Song[] = [];
  isLoading = false;
  model = null;
  search = null;

  buttonLink = YOUTUBE_CHANNEL_URL;

  mounted() {
    this.selectedSongEntry = boodkaFmDataService.getRandomSongEntry();
  }

  @Watch('search')
  searchWatcher(val) {
    if (this.isLoading) return
    this.isLoading = true
    boodkaFmDataService.searchSongs(val).subscribe(res => {
      this.entries = res
    }).add(() => this.isLoading = false)
  }

  @Watch('model')
  modelWatcher(song: Song) {
    router.push({path: 'catalogue/' + Base64.encodeURL(song.artist)+ '/' +  Base64.encodeURL(song.title)})
  }
}
